import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/clients/TestimonialsSection.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Colors from '../../utils/Colors';

/**
 * Testimonial section component for clients page
 */
class TestimonialsSection extends React.Component {

	/**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {

		super(props);

		// Ref to carousel
		this.carousel = React.createRef();

		this.state = {
			// is previous button active
			isPrevActive: false,
			// is next button active
			isNextActive: true,
			// current carousel index
			currentIndex: 0,
			// nb items in a carousel slide
			itemsInSlide: 3
		};

		// index of bigger testimonial item
		this.carouselBiggerItem = -1;

		this.previousTestimonial = this.previousTestimonial.bind(this);

		this.nextTestimonial = this.nextTestimonial.bind(this);

		this.onResize = this.onResize.bind(this);

		this.onSlideChanged = this.onSlideChanged.bind(this);

		this.onInitialized = this.onInitialized.bind(this);
	}

	/**
	 * Go to previous carousel page
	 */
	previousTestimonial() {
		this.carousel.current.slidePrev();

		if (this.state.currentIndex - 1 > 0) {
			this.setState({
				isPrevActive: true
			});
		}
		else {
			this.setState({
				isPrevActive: false
			});
		}

		this.setState({
			isNextActive: true
		});

		this.setState({
			currentIndex: this.state.currentIndex - 1
		});
	}

	/**
	 * Go to next carousel page
	 */
	nextTestimonial() {
		this.carousel.current.slideNext();

		if (this.state.currentIndex + 1 + this.state.itemsInSlide < this.props.testimonials.length) {
			this.setState({
				isNextActive: true
			});
		}
		else {
			this.setState({
				isNextActive: false
			});
		}

		this.setState({
			isPrevActive: true
		});

		this.setState({
			currentIndex: this.state.currentIndex + 1
		});
		/**/
	}

	/**
	 * Update state on resize 
	 * @param {CarouselEvent} event Carousel resize event
	 */
	onResize(event) {
		let { itemsInSlide, item } = event;
		let prevActive = item > 0;
		let nextActive = item + itemsInSlide < this.props.testimonials.length;

		if (item + itemsInSlide >= this.props.testimonials.length) {
			item = this.props.testimonials.length - itemsInSlide;

			prevActive = item > 0;
			nextActive = false;
		}

		this.setState({
			itemsInSlide: itemsInSlide,
			currentIndex: item,
			isNextActive: nextActive,
			isPrevActive: prevActive
		});

		// if carousel bigger item is not saved, we try to find it
		if (this.carouselBiggerItem !== -1) {
			this.onInitialized(event, true);
		}
	}

	/**
	 * Update state on carousel slide change
	 * @param {CarouselEvent} event Carousel slidechanged event
	 */
	onSlideChanged(event) {
		this.setState({ currentIndex: event.item });
		this.onResize(event);
	}

	/**
	 * 
	 * @param {CarouselEvent} event Carousel event
	 * @param {boolean} dontSaveBiggerItem True if we do not need to save bigger item
	 */
	onInitialized(event, dontSaveBiggerItem) {
		//this.onResize(event);

		// if (!this.items){
		// List of all testimonials
		this.items = document.querySelectorAll('li:not(.__cloned) .testimonialsSection-item'); //document.getElementsByClassName("testimonialsSection-item");
		//}

		let maxHeight = 0;

		// save bigger item
		if (!dontSaveBiggerItem) {
			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].offsetHeight > maxHeight) {
					maxHeight = this.items[i].offsetHeight;
					this.carouselBiggerItem = i;
				}
			}
			//console.log(maxHeight+" / "+this.carouselBiggerItem);
		}
		else {
			maxHeight = this.items[this.carouselBiggerItem].offsetHeight;

		}

		// Set items height to bigger item height
		for (let i = 0; i < this.items.length; i++) {
			if (i !== this.carouselBiggerItem) {
				this.items[i].style.cssText = 'height:' + maxHeight + 'px; ' + this.items[i].style;
			}
		}
	}

	/**
	 * Init items after render
	 */
	componentDidMount() {
		setTimeout(this.onInitialized, 200);
	}

	render() {

		/* Number of items displayed in carousel based on screen width */
		let responsive = {
			0: {
				items: 1
			},
			1024: {
				items: 3
			}
		};

		let items = this.props.testimonials.map((item, index) => {
			let colorClass = 'testimonialsSection-item-footer-company-' + Colors.getColorNameByNumber(index);

			return (
				<div key={'testimonial-' + index} className="testimonialsSection-item">
					<div className="testimonialsSection-item-text emg-text">{item.elements.text.value}</div>
					<div className="testimonialsSection-item-footer">
						<div className="testimonialsSection-item-footer-name">{item.elements.name.value}</div>
						<div className="testimonialsSection-item-footer-job">{item.elements.job.value}</div>
						<div className={colorClass + ' testimonialsSection-item-footer-company'}>
							<span>{item.elements.company.value}</span>
						</div>
					</div>
				</div>
			);
		});

		let nextButtonClass = '';
		if (!this.state.isNextActive) {
			nextButtonClass = ' disabled';
		}

		let prevButtonClass = '';
		if (!this.state.isPrevActive) {
			prevButtonClass = ' disabled';
		}

		return (
			<div className="testimonialsSection section">
				<Container fluid className="testimonialsSection-content">
					<Row>
						<Col xs={{ span: 1, offset: 0 }} className="testimonialsSection-arrow-col">
							<div
								className={'hidden-small-version testimonialsSection-arrow-left' + prevButtonClass}
								onClick={this.previousTestimonial}
							/>
						</Col>
						<Col xs={{ span: 10, offset: 0 }} className="testimonialsSection-testimonialCarousel">
							<AliceCarousel
								className="testimonialsSection-carousel"
								mouseTrackingEnabled={true}
								autoHeight={false}
								items={items}
								startIndex={this.state.currentIndex}
								infinite={false}
								duration={500}
								dotsDisabled={true}
								buttonsDisabled={true}
								responsive={responsive}
								ref={this.carousel}
								onInitialized={this.onResize}
								onResized={this.onResize}
								onSlideChanged={this.onSlideChanged}>
								{}
							</AliceCarousel>
						</Col>
						<Col xs={{ span: 1, offset: 0 }} className="hidden-small-version testimonialsSection-arrow-col">
							<div className={'testimonialsSection-arrow-right' + nextButtonClass} onClick={this.nextTestimonial} />
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default TestimonialsSection;
