import React from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import '../css/Main.css';
import '../css/Clients.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TitleSection from '../components/home/TitleSection';
import LogosSection from '../components/clients/LogosSection';
import TestimonialsSection from '../components/clients/TestimonialsSection';
import MainLayout from '../components/MainLayout';
import SectionCorner from '../components/common/SectionCorner';

/**
 * Clients page
 */
class Clients extends React.Component {

  /**
   * Returns corner position based on index 
   * @param {int} index Index of section
   */
	getCornerPosition(index) {
		let position = '';

		if (index % 5 === 1) {
			position = 'bottom';
		}

		if (index % 5 === 4) {
			position = 'top';
		}

		return position;
	}

	render() {
		let titleSectionData = this.props.data.allKontentItemClientspage.nodes[0].elements;
		let sectionsListData = this.props.data.allKontentItemClientspage.nodes[0].elements.logo_sections.value;
		let testimonialsSectionData = this.props.data.allKontentItemClientspage.nodes[0].elements.testimonials.value;

		return (
			<MainLayout
				activeItem={4}
				scrollSnap={false}
				className="clients-container"
				footerClass={'clients-background-' + (sectionsListData.length + 1) % 5}>
        {/* Title section */}
				<Row className="nearly-fullheight clients-title fullheight">
					<TitleSection
						background={titleSectionData.title_background.value[0].url}
						title={titleSectionData.title.value}
						text={titleSectionData.subtitle.value}
						lineColor="emg-pink"
					/>
				</Row>
        {/* Logos section */}
				<Row className="clients-title-margin" />
				{sectionsListData.map((item, index) => (
					<Row key={'clients-logosection-' + index} className={'clients-background-' + index % 5}>
						<SectionCorner position={this.getCornerPosition(index)} />
						<LogosSection
							title={item.elements.title.value}
							text={item.elements.description.value}
							logos={item.elements.logos.value}
							sectionIndex={index}
						/>
					</Row>
				))}

        {/* Testimonials section */}
				<Row className={'clients-background-' + sectionsListData.length % 5}>
					<SectionCorner position="top" />
					<TestimonialsSection testimonials={testimonialsSectionData} />
				</Row>
			</MainLayout>
		);
	}
}
export default Clients;
export const query = graphql`
	query Clients {
		allKontentItemClientspage {
			nodes {
				elements {
					title {
						value
					}
					subtitle {
						value
					}
					title_background {
						value {
							url
						}
					}
					testimonials {
						value {
							... on kontent_item_clienttestimonial {
								elements {
									company {
										value
									}
									job {
										value
									}
									name {
										value
									}
									text {
										value
									}
								}
							}
						}
					}
					logo_sections {
						value {
							... on kontent_item_clientsection {
								id
								elements {
									description {
										value
									}
									logos {
										value {
											url
										}
									}
									title {
										value
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
