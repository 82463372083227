import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/clients/LogosSection.css';
import Breakpoint from '../../utils/Breakpoints';

/**
 * Logo Grid component used in clients page
 */
class LogosSection extends React.Component {

	/**
     * Constructor
     * @param {*} props Component props
     */
	constructor(props) {
		
		super(props);

		// Init state with 9 logos displayed (6 when small)
		this.state = {
			maxLogos: 9
		};

		this.clickMore = this.clickMore.bind(this);
		this.checkChangeToSmall = this.checkChangeToSmall.bind(this);

		this.logoCol = React.createRef();
	}

	/**
	 * Called when "More" button clicked : add 6 logos on screen
	 */
	clickMore() {
		this.setState({
			maxLogos: this.state.maxLogos + 6
		});
	}

	componentDidMount() {

		// Init and subscribe to reponsive change (to small)
		Breakpoint.init();

		Breakpoint.subscribeSpecific('md', this.checkChangeToSmall);

		this.checkChangeToSmall(Breakpoint.breakpointsValues);
	}

	/**
	 * Called when window resized and is goign from small to big or big to small
	 * @param {*} breakpoints Breakpoints values object
	 */
	checkChangeToSmall(breakpoints) {
		// if small : display 6 logos
		if (breakpoints.md) {
			this.setState({
				maxLogos: 6
			});
		}
		else {
			// if big : display 9 logos
			this.setState({
				maxLogos: 9
			});
		}
	}

	render() {
		// draw blue end line
		let endLine = <div className="logosSection-line-end-full" />;

		// check if we have to add "+ MORE" button
		if (this.props.logos.length > this.state.maxLogos) {
			endLine = (
				<div className="logosSection-line-end">
					<div className="logosSection-line-end-line" />
					
					<div className="logosSection-line-end-more emg-text" onClick={this.clickMore}>
						<div className="logosSection-line-end-plus" />
						MORE
					</div>
				</div>
			);
		}

		return (
			<div className="section">
				<Container fluid className="logosSection-content">
					<Row>
						{/* Text column */}
						<Col className="mb-4"
							xl={{ span: 3, offset: 1 }}
							lg={{ span: 3, offset: 1 }}
							md={{ span: 7, offset: 1 }}
							xs={{ span: 10, offset: 1 }}>
							<div className={'emg-title'}>{this.props.title}</div>
							<div
								className={'emg-text'}
								dangerouslySetInnerHTML={{
									__html: this.props.text
								}}
							/>
						</Col>
						{/* Logos column */}
						<Col
							className="logosSection-logosColumn"
							xl={{ span: 6, offset: 1 }}
							lg={{ span: 6, offset: 1 }}
							md={{ span: 10, offset: 1 }}
							xs={{ span: 10, offset: 1 }}
							ref={this.logoCol}>
							{this.props.logos.map((item, index) => {
								let e = '';

								if (index < this.state.maxLogos) {
									e = (
										<div
											className="logosSection-logo"
											key={'img-logo-' + this.props.sectionIndex + '-' + index}
											style={{ backgroundImage: 'url(' + item.url + ')' }}>
											{/* HACK IE : image non affichée mais présente pour déterminer la taille*/}
											<img className="logosSection-logoImg" src={item.url} alt="logo" />
										</div>
									);
								}

								return e;
							})}
							{endLine}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default LogosSection;
